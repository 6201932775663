import { FC } from 'react';
import IconProps from './IconProps';

const ConsentManagementIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.606 3.63374C16.474 3.62887 13.396 4.45 10.6826 6.01432C7.96917 7.57866 5.71621 9.83089 4.15099 12.5438C2.58577 15.2568 1.76364 18.3345 1.76752 21.4666C1.77137 24.5837 2.5932 27.6449 4.15052 30.3445L1.85571 37.2216L1.8557 37.2216C1.76301 37.4995 1.74956 37.7978 1.81685 38.0829C1.88415 38.3681 2.02953 38.6289 2.23671 38.8361C2.44388 39.0432 2.70467 39.1886 2.98982 39.2559C3.27498 39.3232 3.57325 39.3097 3.85119 39.2171L10.7283 36.9222C13.0872 38.28 15.7257 39.0799 18.4417 39.2605C21.1728 39.442 23.909 38.9925 26.4384 37.9467C28.9678 36.9009 31.2225 35.2869 33.0279 33.2298C34.8334 31.1726 36.141 28.7274 36.8496 26.0837C37.5583 23.4399 37.6488 20.6685 37.1143 17.9842C36.5798 15.2998 35.4345 12.7745 33.7673 10.6038C32.1 8.43317 29.9554 6.67543 27.4997 5.46674C25.044 4.25807 22.3431 3.63088 19.606 3.63374ZM19.606 3.63374C19.606 3.63374 19.6059 3.63374 19.6059 3.63374L19.606 3.73342L19.6062 3.63374C19.6061 3.63374 19.6061 3.63374 19.606 3.63374ZM7.3722 30.6421C7.44619 30.4205 7.47007 30.1854 7.44211 29.9535C7.41414 29.7216 7.33504 29.4988 7.21051 29.3012L7.21047 29.3012C5.47224 26.5472 4.69924 23.2934 5.01315 20.0519C5.32707 16.8104 6.71007 13.7654 8.94445 11.3961C11.1788 9.02691 14.1377 7.46802 17.3552 6.9649C20.5728 6.46177 23.8663 7.04297 26.7173 8.617C29.5683 10.191 31.8148 12.6685 33.1033 15.6594C34.3918 18.6503 34.649 21.9848 33.8345 25.138C33.0199 28.2911 31.1799 31.0838 28.604 33.0765C26.0282 35.0691 22.8628 36.1486 19.6061 36.1448C16.831 36.143 14.1137 35.3514 11.7719 33.8625L11.7717 33.8623L7.27765 30.6105M7.3722 30.6421L7.27765 30.6105M7.3722 30.6421L7.37222 30.642L7.27765 30.6105M7.3722 30.6421L5.84296 35.2298M7.27765 30.6105L5.72475 35.2692L5.84296 35.2298M5.84296 35.2298L10.4301 33.7008C10.4302 33.7007 10.4303 33.7007 10.4304 33.7006L10.4307 33.7005L10.4623 33.7951L5.80356 35.348L5.84296 35.2298Z"
          fill="url(#paint0_linear_11030_107449)"
          stroke="url(#paint1_linear_11030_107449)"
          strokeWidth="0.199367"
        />
        <path
          d="M15.6169 20.5695C14.9814 19.9491 13.9669 19.9491 13.3315 20.5695C12.6742 21.2113 12.6742 22.2684 13.3315 22.9102L17.1027 26.5923C17.413 26.8957 17.8341 27.0664 18.2733 27.0668C18.4954 27.0631 18.7145 27.0159 18.9175 26.9279C19.1205 26.8399 19.3033 26.7129 19.4551 26.5546L26.5766 19.1377C27.2134 18.4744 27.1789 17.4169 26.5003 16.7966C25.8439 16.1967 24.8285 16.2308 24.2139 16.8735L18.2347 23.1255L15.6169 20.5695Z"
          fill="#5A5766"
        />
        <defs>
          <linearGradient id="paint0_linear_11030_107449" x1="1.86719" y1="14.5629" x2="37.3505" y2="29.7417" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_11030_107449" x1="1.86719" y1="14.5629" x2="37.3505" y2="29.7417" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2029 2.03334C9.40561 2.03056 7.63937 2.50176 6.0823 3.39943C4.5252 4.29712 3.23235 5.58955 2.33416 7.14636C1.43597 8.70317 0.96419 10.4693 0.966414 12.2666C0.96862 14.0489 1.43683 15.7993 2.32422 17.3444L1.01902 21.2558L1.01901 21.2558C0.963519 21.4222 0.955466 21.6008 0.995756 21.7715C1.03605 21.9422 1.12309 22.0984 1.24713 22.2224C1.37117 22.3465 1.5273 22.4335 1.69803 22.4738C1.86875 22.5141 2.04733 22.506 2.21374 22.4505L2.21375 22.4505L6.12524 21.1453C7.47455 21.9186 8.98256 22.3743 10.5347 22.4775C12.1019 22.5816 13.6721 22.3237 15.1235 21.7236C16.575 21.1234 17.8689 20.1973 18.9049 19.0168C19.9409 17.8363 20.6913 16.4331 21.098 14.9161C21.5046 13.399 21.5566 11.8086 21.2498 10.2682C20.9431 8.72781 20.2859 7.27869 19.3292 6.03307C18.3724 4.78746 17.1418 3.77879 15.7326 3.0852C14.3234 2.39162 12.7735 2.03171 11.2029 2.03334ZM11.2029 2.03334C11.2029 2.03334 11.2028 2.03334 11.2028 2.03334L11.2029 2.13334L11.203 2.03334C11.203 2.03334 11.2029 2.03334 11.2029 2.03334ZM6.74931 19.3136L6.74913 19.3135C6.59797 19.2178 6.42274 19.1671 6.24385 19.1671H6.24373C6.14239 19.1673 6.04177 19.184 5.94585 19.2167C5.94576 19.2167 5.94567 19.2168 5.94558 19.2168L3.40632 20.0632L4.25295 17.5234C4.25295 17.5233 4.25296 17.5233 4.25297 17.5233C4.29725 17.3907 4.31154 17.2499 4.2948 17.1111C4.27806 16.9723 4.2307 16.8389 4.15614 16.7206L4.15611 16.7205C3.16793 15.1549 2.72848 13.3051 2.90694 11.4623C3.0854 9.61957 3.87163 7.88848 5.14187 6.54157C6.4121 5.19467 8.0942 4.30845 9.92336 4.02242C11.7525 3.73639 13.6249 4.06681 15.2457 4.96164C16.8664 5.85647 18.1436 7.26491 18.8761 8.96524C19.6086 10.6656 19.7548 12.5612 19.2917 14.3537C18.8287 16.1463 17.7826 17.7339 16.3183 18.8668C14.8539 19.9996 13.0544 20.6132 11.203 20.6111H11.2029C9.62534 20.6101 8.08061 20.1601 6.74931 19.3136ZM11.2029 20.7111C9.60629 20.7101 8.04297 20.2546 6.69565 19.398L11.2029 20.7111Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        d="M8.9235 11.754C8.56038 11.3995 7.98069 11.3995 7.61756 11.754C7.24196 12.1207 7.24196 12.7248 7.61756 13.0915L9.77253 15.1956C9.94989 15.369 10.1905 15.4665 10.4415 15.4667C10.5684 15.4646 10.6936 15.4376 10.8096 15.3874C10.9256 15.3371 11.03 15.2645 11.1168 15.174L15.1862 10.9358C15.5501 10.5568 15.5304 9.95251 15.1426 9.59806C14.7675 9.25525 14.1873 9.27476 13.8361 9.64199L10.4194 13.2146L8.9235 11.754Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default ConsentManagementIcon;
